import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import Section from '../../../common/SectionComponent/Section'
import IndustryPlates from '../../Industry/IndustryPlatesSection/IndustryPlates'

const OneBusinessIndustryPlates = ({ heading, data }) => {
  const plates = data
    .filter((e) => e.gamingIndustryItem)
    .map((e) => {
      const { title, description, image } = e.gamingIndustryItem
      return {
        image:
          image.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
        title,
        description,
        link: null,
        buttonText: '',
      }
    })

  return (
    <Section title={heading.title}>
      <Fade bottom>
        <p className="text-color__gray--on-dark">{heading.description}</p>
      </Fade>
      <IndustryPlates onDark platesData={plates} maxPlates={6} />
    </Section>
  )
}

export default OneBusinessIndustryPlates
