import React from 'react'

import { graphql } from 'gatsby'
import MainLayout from '../../layouts/MainLayout'
import OneBusinessHeader from '../../components/Pages/Businesses/OneBusinessHeader/OneBusinessHeader'
import OneBusinessStages from '../../components/Pages/Businesses/OneBusinessStages/OneBusinessStages'
import OneBusinessSolutions from '../../components/Pages/Businesses/OneBusinessSolutions/OneBusinessSolutions'
import OneBusinessIndustryPlates from '../../components/Pages/Businesses/OneBusinessIndustryPlates/OneBusinessIndustry'
import GamingSpecial from '../../components/Pages/Businesses/GamingSpecial/GamingSpecial'
import OneServicePortfolio from '../../components/Pages/OneService/OneServicePortfolio/OneServicePortfolio'
import MainClients from '../../components/Pages/main/MainClients/MainClients'
import OneServiceBlog from '../../components/Pages/OneService/OneServiceBlog/OneServiceBlog'
import CompanyStrategy from '../../components/Pages/Company/CompanyStrategy/CompanyStrategy'
import GamingInHouse from '../../components/Pages/Businesses/GamingInHouse/GamingInHouse'

const BusinessesGaming = ({ data, location }) => {
  const { page_gaming: pageData, metaTags } = data.wpPage
  const {
    gamingSolutionHeading,
    gamingSolutionsItems,
    gamingIndustryHeading,
    gamingIndustry,
    gamingSpecialsItem,
    gamingSubtitle,
    gamingSpecialHeading,
    gamingPortfolioButton,
    gamingPortfolioHeading,
    gamingPortfolioItems,
    gamingClientsSection,
    blogPosts,
    blogHeader,
    whyUsHeading,
    whyUsItems,
    inHousePortfolioItems,
    inHouseHeading,
    inHouseButton,
  } = pageData
  return (
    <MainLayout seoData={metaTags} location={location}>
      <OneBusinessHeader data={pageData} button={pageData.gamingHeaderButton} />
      <OneBusinessStages data={pageData.stagesSection} />
      <GamingSpecial
        data={gamingSpecialsItem}
        heading={gamingSpecialHeading}
        subtitle={gamingSubtitle}
      />
      <OneBusinessSolutions
        headingData={gamingSolutionHeading}
        items={gamingSolutionsItems}
      />
      <OneBusinessIndustryPlates
        heading={gamingIndustryHeading}
        data={gamingIndustry}
      />
      <CompanyStrategy isGray heading={whyUsHeading} items={whyUsItems} />
      <OneServicePortfolio
        title={gamingPortfolioHeading.title}
        description={gamingPortfolioHeading.description}
        buttonData={gamingPortfolioButton}
        portfolioItems={gamingPortfolioItems}
      />
      <GamingInHouse
        plates={inHousePortfolioItems}
        buttonData={inHouseButton}
        heading={inHouseHeading}
      />
      <MainClients
        isLight
        clientItems={gamingClientsSection.clientItems}
        heading={gamingClientsSection.clientsHeader}
      />
      <OneServiceBlog data={blogPosts} heading={blogHeader} />
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 66 }) {
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_gaming {
        headerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        headerText
        stagesSection {
          stagesHeader {
            description
            title
          }
          stagesBusiness {
            ... on WpBusiness {
              business {
                stages {
                  description
                  title
                }
              }
            }
          }
        }
        gamingHeaderButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        gamingSolutionHeading {
          description
          title
        }
        gamingSolutionsItems {
          description
          title
        }
        gamingIndustryHeading {
          title
          description
        }
        gamingIndustry {
          gamingIndustryItem {
            description
            title
            image {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        gamingSpecialsItem {
          title
          description
        }
        gamingSubtitle
        gamingSpecialHeading {
          description
          title
        }
        gamingPortfolioHeading {
          description
          title
        }
        gamingPortfolioButton {
          filterBy
          text
          service {
            slug
          }
          industry {
            slug
          }
          technology {
            slug
          }
        }
        gamingPortfolioItems {
          ... on WpPortfolio_item {
            uri
            portfolioItem {
              title
              buttonText
              embeddedHtml
              type
              image {
                sourceUrl
              }
              plateVideo {
                localFile {
                  url
                }
              }
            }
          }
          ... on WpPortfolio_item {
            portfolioItem {
              caseStudySections {
                ... on WpPortfolio_item_Portfolioitem_CaseStudySections_Client {
                  playbackButton {
                    text
                    embeddedHtml
                  }
                }
              }
            }
          }
        }
        gamingClientsSection {
          clientItems {
            ... on WpClient {
              client {
                title
                name
                description
                photo {
                  localFile {
                    url
                  }
                }
                country {
                  localFile {
                    childImageSharp {
                      resize(width: 24, height: 24) {
                        src
                      }
                    }
                  }
                  altText
                  title
                }
              }
            }
          }
          clientsHeader {
            description
            title
          }
        }
        blogHeader {
          description
          fieldGroupName
          title
        }
        blogPosts {
          ... on WpPost {
            uri
            link
            title
            blogPost {
              blogPrewiev
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              minutesToRead
              tags {
                name
              }
            }
            blogAuthors {
              nodes {
                name
                blogAuthor {
                  photo {
                    altText
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
        whyUsHeading {
          description
          title
        }
        whyUsItems {
          description
          title
        }
        inHousePortfolioItems {
          ... on WpPortfolio_item {
            portfolioItem {
              title
            }
          }
        }
        inHouseHeading {
          description
          title
        }
        inHouseButton {
          filterBy
          text
          industry {
            slug
          }
          service {
            slug
          }
          technology {
            slug
          }
        }
      }
    }
  }
`

export default BusinessesGaming
