import React from 'react'
import Fade from 'react-reveal/Fade'
import classnames from 'classnames'
import * as style from './company-strategy.module.scss'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'

const CompanyStrategy = ({ items, isGray, heading }) => {
  const { title, description } = heading
  return (
    <Section isLight newClass={isGray && style.grayBg} title={title}>
      <p className="text-color__gray--on-light">{description}</p>
      <div className={style.plates}>
        {Object.keys(items).map((elem, index) => (
          <Fade bottom key={keyGenerator(index, elem.title)}>
            <div className={style.plate}>
              <h3 className={style.title}>{items[elem].title}</h3>
              <p className={style.description}>{items[elem].description}</p>
            </div>
          </Fade>
        ))}
      </div>
    </Section>
  )
}

export default CompanyStrategy
