import Fade from 'react-reveal/Fade'
import React from 'react'
import className from 'classnames'
import Section from '../../../common/SectionComponent/Section'
import * as gamingStyle from './gaming-specials.module.scss'
import * as style from '../StartupsSpecials/startups-specials.module.scss'
import bgImage from '../../../../../static/images/gaming-bg.png'

const GamingSpecial = ({ data, heading, subtitle }) => {
  const { title, description } = heading
  return (
    <div
      className={style.wrap}
      style={{ backgroundImage: `url('${bgImage}')` }}
    >
      <Section newClass={style.background} title={title}>
        <Fade bottom>
          <p className="text-color__gray--on-dark">{description}</p>
        </Fade>
        <h3 className={gamingStyle.subtitle}>{subtitle}</h3>
        <div className={gamingStyle.items}>
          {data.map((d) => (
            <div className={gamingStyle.item}>
              <h4 className={gamingStyle.heading}>{d.title}</h4>
              <p
                className={className(
                  gamingStyle.description,
                  'text-color__gray--on-dark'
                )}
              >
                {d.description}
              </p>
            </div>
          ))}
        </div>
      </Section>
    </div>
  )
}

export default GamingSpecial
