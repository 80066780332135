import Fade from 'react-reveal/Fade'
import React from 'react'
import className from 'classnames'
import * as style from './gaming-out-in.module.scss'
import * as section from '../../../common/SectionComponent/section-component.module.scss'
import bg from '../../../../../static/images/inhousegamesbg.2fb4745.png'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'

const GamingInHouse = ({ heading, buttonData, plates }) => {
  const { filterBy } = buttonData

  const uri = !filterBy
    ? ''
    : `/portfolio/?${filterBy}=${buttonData[buttonData.filterBy].slug}`
  const { title, description } = heading
  return (
    <section
      style={{ backgroundImage: `url('${bg}')` }}
      className={className(section.section, style.bg)}
    >
      <div className="container">
        <div className={style.wrap}>
          <div>
            <Fade bottom>
              <h2 className={section.title}>{title}</h2>
            </Fade>
            <Fade bottom>
              <p className="text-color__gray--on-dark">{description}</p>
            </Fade>
            <div className={style.button}>
              <ButtonPrimary
                buttonData={{ url: { uri }, text: buttonData.text }}
              />
            </div>
          </div>
          <div className={style.hoverPlates}>
            {plates.map((e) => (
              <div className={style.hoverPlate}>
                <h3 className={style.hoverPlateTitle}>
                  {e.portfolioItem.title}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default GamingInHouse
